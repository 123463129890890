"use strict";

/* eslint-disable max-params */
(function () {
  var INITIAL_RETRY_TIMEOUT = 1000;
  var ONE_MINUTE = 60;
  var MAX_RETRY_TIMEOUT = ONE_MINUTE * INITIAL_RETRY_TIMEOUT;
  var RETRY_TIMEOUT_MULTIPLIER = 2;
  function dependencies(window) {
    return {
      fetch: window.fetch,
      Request: window.Request,
      convertObjectFieldsInSnakeCase: window.convertObjectFieldsInSnakeCase
    };
  }
  function RetryableStrategy(_ref, _ref2) {
    var httpMethod = _ref.httpMethod,
      _ref$serializeAsUri = _ref.serializeAsUri,
      serializeAsUri = _ref$serializeAsUri === void 0 ? false : _ref$serializeAsUri,
      _ref$serializeAsPaylo = _ref.serializeAsPayload,
      serializeAsPayload = _ref$serializeAsPaylo === void 0 ? false : _ref$serializeAsPaylo;
    var parseJson = _ref2.parseJson,
      verifyError = _ref2.verifyError,
      serializeUrlEncoded = _ref2.serializeUrlEncoded;
    return function executeRequest(uri, data, signal, injection) {
      var _Object$assign = Object.assign({}, dependencies(window), injection),
        fetch = _Object$assign.fetch,
        Request = _Object$assign.Request,
        convertObjectFieldsInSnakeCase = _Object$assign.convertObjectFieldsInSnakeCase;
      var convertedData = convertObjectFieldsInSnakeCase(data);
      var uriParameters = serializeAsUri ? serializeUrlEncoded(convertedData) : '';
      var gaeRequest = new Request("".concat(uri).concat(uriParameters), {
        credentials: 'include',
        method: httpMethod,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: serializeAsPayload ? JSON.stringify(convertedData) : null
      });
      return new Promise(function (resolve, reject) {
        function fetchAttempt(timeout) {
          fetch(gaeRequest.clone(), {
            signal: signal
          }).then(parseJson).then(verifyError).then(resolve)["catch"](function (error) {
            if (error instanceof TypeError) {
              var newTimeout = timeout * RETRY_TIMEOUT_MULTIPLIER > MAX_RETRY_TIMEOUT ? MAX_RETRY_TIMEOUT : timeout * RETRY_TIMEOUT_MULTIPLIER;
              console.warn("Failed to fetch. Retrying in ".concat(newTimeout / INITIAL_RETRY_TIMEOUT, " second(s)."));
              setTimeout(function () {
                fetchAttempt(newTimeout);
              }, newTimeout);
            } else {
              reject(error);
            }
          });
        }
        fetchAttempt(INITIAL_RETRY_TIMEOUT);
      });
    };
  }
  window.moduleExport({
    RetryableStrategy: RetryableStrategy
  }, typeof module !== 'undefined' && module);
})();